import React, { createRef, lazy, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LocationLanguageHandler from "pages/util/LocationLanguageHandler";
import { useStatePersistence } from "state/persist";
import PageLoader from "util/PageLoader";

const LanguageRedirect = lazy(() => import("pages/LanguageRedirect"));

const Playground = lazy(() => import("pages/Playground"));

declare global {
  interface Window {
    AppPageLoader: React.RefObject<PageLoader>;
  }
}

window.AppPageLoader = createRef<PageLoader>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppPageLoader = (props: any = {}) => (
  <PageLoader
    {...props}
    ref={window.AppPageLoader}
    routingExceptions={{
      // product search has it's own routing because its an application that another agency (mbase) implemented
      // there is also a magnolia configuration that prevents the distribution of a 404 page (and distributes the react spa instead)
      "/product-search/": (pagePath: string) => {
        const productSearchIdentifier = "/product-search/";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/products-overview/": (pagePath: string) => {
        const productSearchIdentifier = "/products-overview/";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/products-brands/": (pagePath: string) => {
        const productSearchIdentifier = "/products-brands/";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/press": (pagePath: string) => {
        const productSearchIdentifier = "/press";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/press~": (pagePath: string) => {
        const productSearchIdentifier = "/press";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/blog~": (pagePath: string) => {
        const productSearchIdentifier = "/blog";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/htf-products/": (pagePath: string) => {
        const productSearchIdentifier = "/htf-products";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/career/graduate": (pagePath: string) => {
        const productSearchIdentifier = "/career/graduate";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/career/student": (pagePath: string) => {
        const productSearchIdentifier = "/career/students";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/career/professional": (pagePath: string) => {
        const productSearchIdentifier = "/career/professional";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/career/job-starter": (pagePath: string) => {
        const productSearchIdentifier = "/career/job-starter";
        const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

        return pagePath.substring(
          0,
          indexOfProductSearch + productSearchIdentifier.length
        );
      },
      "/orders": (pagePath: string) => {
        const orderIdentifier = "/orders";
        const indexOfOrder = pagePath.indexOf(orderIdentifier);

        return pagePath.substring(0, indexOfOrder + orderIdentifier.length);
      },
      // For dev reasons only
      "/playground": (pagePath: string) => {
        const playgroundIdentifier = "/playground";
        const indexOfPlayground = pagePath.indexOf(playgroundIdentifier);

        return pagePath.substring(
          0,
          indexOfPlayground + playgroundIdentifier.length
        );
      },
      // krahnChemie products
      "/industries": (pagePath: string) => {
        const productIdentifier = "/industries";
        const indexOfContact = pagePath.indexOf(productIdentifier);
        if (
          pagePath.includes("scientific") ||
          pagePath.includes("specialty")
        ) {
          return pagePath;
        }

        return pagePath.substring(0, indexOfContact + productIdentifier.length);
      },
      "/career/jobs": (pagePath: string) => {
        return pagePath;
      },
      // job detail pages
      "/career/job/": (pagePath: string) => {
        const detailIndentifier = "/career/job";
        const indexOfJob = pagePath.indexOf(detailIndentifier);
        return pagePath.substring(0, indexOfJob + detailIndentifier.length);
      },
      "/career/job-cf/": (pagePath: string) => {
        const detailIndentifier = "/career/job-cf";
        const indexOfJob = pagePath.indexOf(detailIndentifier);
        return pagePath.substring(0, indexOfJob + detailIndentifier.length);
      },
      "/about/career/job/": (pagePath: string) => {
        const detailIndentifier = "/about/career/job";
        const indexOfJob = pagePath.indexOf(detailIndentifier);
        if (pagePath.includes("/career/jobs")) {
          return pagePath;
        }
        return pagePath.substring(0, indexOfJob + detailIndentifier.length);
      },
      // krahn Ceramics
      "/job/": (pagePath: string) => {
        const detailIndentifier = "/job";
        const indexOfJob = pagePath.indexOf(detailIndentifier);
        if (pagePath.includes("/career/jobs")) {
          return pagePath;
        }
        return pagePath.substring(0, indexOfJob + detailIndentifier.length);
      },
    }}
  />
);

const App = () => {
  useStatePersistence();

  useEffect(() => {
    if (window.location.hash.length > 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <BrowserRouter>
      <LocationLanguageHandler />
      <Switch>
        <Route
          path="/:url*(/+)"
          exact
          strict
          render={({ location }) => (
            <Redirect to={location.pathname.slice(0, -1)} />
          )}
        />
        <Route exact path="/" component={LanguageRedirect} />
        {process.env.NODE_ENV === "development" && (
          <Route path="/protofy/playground" component={Playground} />
        )}
        <Route path="/" component={AppPageLoader} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
